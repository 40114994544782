import React from 'react';
// import prismicLogo from '../images/logo-prismic.svg';

const Footer = () => (
	<footer>
		<p>
			{/* Proudly published with{' '}
			<a href="https://prismic.io" target="_blank" rel="noopener noreferrer">
				Prismic
			</a>
			<br />
			<a href="https://prismic.io" target="_blank" rel="noopener noreferrer">
				<img className="footer-logo" src={prismicLogo} alt="Prismic logo" />
			</a> */}
			&#169;2021
		</p>
	</footer>
);

export default Footer;
